export const PrevMonthChallengesLB = [
    {username: 'ChanChinda', completed: 61, earned: 4003.00, prize: '$4000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Smokey1', completed: 36, earned: 2875.00, prize: '$2000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Junkoishere', completed: 21, earned: 1867.00, prize: '$1000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Reelss', completed: 21, earned: 1379.00, prize: '750', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'WeInThisBih', completed: 19, earned: 1426.00, prize: '600', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'lgd', completed: 15, earned: 1406.00, prize: '500', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rekehh', completed: 15, earned: 768.00, prize: '400', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Fuqboy', completed: 13, earned: 995.00, prize: '300', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'ElderBTC', completed: 13, earned: 2770.00, prize: '250', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'huncho100', completed: 4, earned: 367.00, prize: '200', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'OopAStae', completed: 3, earned: 285.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'GGE', completed: 2, earned: 310.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: '999bredren', completed: 2, earned: 152.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN1', completed: 2, earned: 105.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jollyrogger', completed: 2, earned: 130.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
]

export const PrevMonthWagerLB = [
    {username: 'Pet', totalWagered: 2576292.17, prize: 12500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Shr', totalWagered: 1257758.87, prize: 8000, image: 'https://btcs.gg/newcoin.svg'},
    {username: '555', totalWagered: 1238315.71, prize: 6000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Hat', totalWagered: 1216418.27, prize: 4000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gra', totalWagered: 806558.81, prize: 3500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Fri', totalWagered: 660981.65, prize: 2750, image: 'https://btcs.gg/newcoin.svg'},
    {username: '6pa', totalWagered: 624497.04, prize: 2000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jol', totalWagered: 584416.86, prize: 1500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Ven', totalWagered: 513901.42, prize: 1250, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'mtu', totalWagered: 432176.36, prize: 1000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'asu', totalWagered: 364763.88, prize: 850, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'LTC', totalWagered: 351880.16, prize: 800, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jdu', totalWagered: 346602.55, prize: 750, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'dan', totalWagered: 320879.21, prize: 700, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'tom', totalWagered: 287927.61, prize: 650, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'the', totalWagered: 281606.00, prize: 600, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Slo', totalWagered: 268885.92, prize: 550, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Man', totalWagered: 255432.96, prize: 500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Btc', totalWagered: 251853.00, prize: 450, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jua', totalWagered: 233813.11, prize: 400, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'jun', totalWagered: 190267.24, prize: 350, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Cat', totalWagered: 172066.67, prize: 300, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gth', totalWagered: 170674.64, prize: 250, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'neb', totalWagered: 147714.65, prize: 200, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Mcw', totalWagered: 142704.32, prize: 150, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Sa1', totalWagered: 133201.16, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Cha', totalWagered: 132630.56, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Sat', totalWagered: 126824.19, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN', totalWagered: 122496.41, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'nam', totalWagered: 106849.96, prize: 0, image: 'https://btcs.gg/newcoin.svg'}
]